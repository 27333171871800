<template>
	<div class="mine">
		<div class="user-info-wrap">
			<div class="user-photo"><img :src="photo" alt="" /></div>
			<div class="user-info">
				<div class="user-info-item">{{ userData.name }}</div>
				<div class="user-info-item">{{ userData.username }}</div>
			</div>
		</div>

		<div class="bar-wrap" v-if="barOptions.length">
			<p class="title" @click="barTitleEvent">{{ barTitle }}</p>
			<div class="bar-list">
				<div class="bar-item" v-for="(item, index) in barOptions" :key="index" @click="barEvent(item)">
					<div class="icon-wrap">
						<img :src="item.icon" alt="" />
						<span class="badge" v-if="item.badge">{{ item.badge }}</span>
					</div>
					<p class="label">{{ item.label }}</p>
				</div>
			</div>
		</div>

		<div class="cell-wrap" v-show="cellOptions.length > 0">
			<div class="cell-list">
				<div class="cell-item" v-for="(item, index) in cellOptions" :key="index" role="button" @click="cellEvent(item)">
					<img :src="item.icon" alt="" />
					<p class="label">{{ item.label }}</p>
					<van-icon name="arrow" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
// import userPhoto from '../../../static/iconImage/yonghu.png'
let userPhoto =
	'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fitem%2F202102%2F05%2F20210205114339_bjjly.jpg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1673493191&t=2bf03116f03b47d8d26b84d311285b11';
// let userPhoto = require('../../../static/iconImage/yonghu.png')

export default {
	name: 'minePage',
	components: {},
	props: {
		info: {
			type: Object,
			default: () => {
				return {};
			}
		},
		barTitle: {
			type: String,
			default: ''
		},
		/** 第一栏配置 
		 [
			 {
			 	label: '', // 提示文字
			 	icon: '', // 图片图标
			 	badge: 0, //数字角标
			 	// 可以添加其他参数（点击后会整项返回）
			 }
		 ]
		 */
		barOptions: {
			type: Array,
			default: () => {
				return [];
			}
		},
		/** 第二栏配置
		 [
			 {
			 	label: '', // 提示文字
			 	icon: '', // 图片图标
			 	// 可以添加其他参数（点击后会整项返回）
			 }
		 ]
		 */
		cellOptions: {
			type: Array,
			default: () => {
				return [];
			}
		}
	},
	data() {
		return {
			userData: {
				id: '',
				name: '',
				photo: '',
				tenantId: '',
				tenantName: '',
				username: ''
			}
		};
	},
	computed: {
		photo: function() {
			if (this.$valueIsExist(this.userData, 'photo') && this.userData['photo'] != '') {
				let photo = this.userData['photo'];
				let url = `${window.ipConfig.VUE_APP_BASEURL}/api/image/get?id=${photo}&scale=0.1`;
				return url;
			}
			return userPhoto;
		}
	},
	created() {},
	mounted() {
		if (typeof localStorage['userInfo'] != 'undefined') {
			let weChatUserInfoStr = localStorage.getItem('userInfo');
			try {
				this.userData = JSON.parse(weChatUserInfoStr);
				this.userData['photo'] = '';
			} catch (e) {}
		}
	},
	methods: {
		barTitleEvent() {
			this.$emit('onBarTitle');
		},
		barEvent(item) {
			this.$emit('barChange', item);
		},
		cellEvent(item) {
			this.$emit('cellChange', item);
		}
	}
};
</script>

<style lang="scss" scoped>
@import '@styles/variables.scss';
.mine {
	height: 100%;
	background: linear-gradient(360deg, rgba(114, 171, 253, 0) 0%, #568efc 70%, #3e73fb 100%);
	background-size: 100% 140px;
	background-repeat: no-repeat;
	overflow-y: auto;
	.user-info-wrap {
		display: flex;
		padding: 24px 30px;
		position: relative;
		.user-photo {
			width: 60px;
			height: 60px;
			border-radius: 50%;
			overflow: hidden;
			img {
				width: 100%;
				height: 100%;
			}
		}
		.user-info {
			margin-left: 18px;
			width: calc(100% - 60px -18px);
			display: flex;
			flex-direction: column;
			justify-content: center;
			.user-info-item {
				width: 100%;
				font-size: 14px;
				color: #fff;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				&:not(:last-child) {
					margin-top: 8px;
				}
			}
		}
		.edit-info-btn {
			width: 24px;
			height: 24px;
			background: rgba(0, 0, 0, 0.6);
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			position: absolute;
			right: 20px;
			top: 50%;
			transform: translateY(-50%);
		}
	}
	.bar-wrap + .cell-wrap {
		margin-top: 10px;
	}

	.bar-wrap {
		height: 116px;
		width: calc(100% - 20px);
		margin: 0 10px;
		background: #ffffff;
		border-radius: 12px;
		padding: 12px 12px 0 12px;
		.title {
			font-size: 14px;
			font-weight: 700;
			color: #333333;
		}
		.bar-list {
			width: 100%;
			display: flex;
			justify-content: space-around;
			height: calc(100% - 20px);
			display: flex;
			align-items: center;
			.bar-item {
				display: flex;
				flex-direction: column;
				align-items: center;
				flex: 1;
				.icon-wrap {
					position: relative;
					img {
						width: 24px;
						height: 24px;
					}
					.badge {
						position: absolute;
						left: calc(100% - 8px);
						top: -8px;
						display: inline-block;
						background: #fc6735;
						color: #fff;
						font-size: 12px;
						padding: 2px 4px;
						min-width: 16px;
						text-align: center;
						border-radius: 16px;
						font-weight: 400;
						line-height: 12px;
					}
				}
				.label {
					font-size: 12px;
					color: #333333;
					text-align: center;
					margin-top: 6px;
					font-weight: 400;
				}
			}
		}
	}

	.cell-wrap {
		background: #ffffff;
		border-radius: 12px;
		width: calc(100% - 20px);
		margin-left: 10px;
		.cell-list {
			padding: 6px 0;
			.cell-item {
				display: flex;
				align-items: center;
				padding: 10px 16px;
				cursor: pointer;
				img {
					width: 26px;
					height: 26px;
				}
				.label {
					margin-left: 24px;
					font-size: 14px;
					color: #000;
					font-weight: 400;
					text-align: left;
					width: calc(100% - 26px - 24px - 16px);
				}
				.van-icon {
					font-size: 16px;
					color: #999;
				}
			}
		}
	}
}
</style>

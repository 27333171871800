<template>
  <minePage
      :info="{}"
      barTitle="资产维修"
      :barOptions="barOptions"
      :cellOptions="cellOptions"
      @barChange="barChange"
      @cellChange="cellChange"
      @onBarTitle="onBarTitle"
  ></minePage>
</template>

<script>

import minePage from "@components/minePage";
import deviceBorrowMixin from '@views/shareAndDispatch/deviceBorrow/deviceBorrowMixin'

export default {
  name: '',
  components: { minePage },
  mixins:[deviceBorrowMixin],
  data() {
    return {
      barOptions: [
        {
          label: "报修",
          icon: require("@/icons/imgs/deviceBorrow-mine-bar-1.png"),
          badge: 0,
          page: "deviceRepair",
          key: "",
          query:{viewType:'repair'},
        },
        {
          label: "维修列表",
          icon: require("@/icons/imgs/deviceBorrow-mine-bar-5.png"),
          badge: 0,
          page: "deviceFailureList",
          key: "repair",
          query:{showBackGround:'1', viewType: 'repair'},
        },
      ],
      cellOptions: [
        // {
        //   label: "报障记录",
        //   icon: require("@/icons/imgs/workManager-mine-cell-3.png"),
        //   page: "breakdown",
        // },
        // {
        //   label: "费用记录",
        //   icon: require("@/icons/imgs/workManager-mine-cell-4.png"),
        //   page: "cost",
        // },
      ],
    };
  },
  created() {},
  mounted() {
  },
  methods: {
    getCount() {

    },
    barChange(e) {
      this.$push("/deviceBorrow/" + e.page, e.query);
    },
    cellChange(e) {
      this.$push("/deviceRecord/" + e.page);
    },
    onBarTitle(){

    },
  },
}
</script>

<style lang="scss" scoped>
@import "@styles/variables.scss";
</style>